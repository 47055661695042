@tailwind base;
@tailwind components;
@tailwind utilities;

/* width */
::-webkit-scrollbar {
    width: 18px;
}
 
/* Track */
::-webkit-scrollbar-track {
    background: #000000;
    border: 2px solid rgb(93, 93, 93); /* Correctly specifies a 2px solid white border */
    border-radius: 10px; /* You can also add border-radius to match the thumb */
}
 
/* Handle */
::-webkit-scrollbar-thumb {
    background: linear-gradient(to bottom, #fb923c, #3b82f6);
    border-radius: 10px;
}
 
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #ffa052;
}

@media (pointer: coarse) {
    .gb-container {
        background-attachment: inital !important;
    }
}